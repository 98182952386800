import React from 'react';

import config from '../../config';
import image from './full-logo.png';
export default function Footer() {
  return (
    <header id="header">
      <div class="flex-container">
        <div><img src={image} alt="kirbyware logo"></img></div>
      </div>
      <p>{config.siteHeading}</p>
      {/* <p class="summary">We provide services, solutions, and software to help organizations execute their strategic technology objectives</p> */}
      <br />
      <br />
      <p class="summary">Learn more below</p>
      <nav>
        <ul>
          {config.siteSocialLinks.map(social => {
            const { icon, name, url } = social;
            return (
              <li>
                <a href={url} class={`icon ${icon}`}>
                  <span class="label">{name}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </header>
  );
}
