module.exports = {
  siteTitle: 'kirbyware', // <title>
  manifestName: 'Aerial',
  manifestShortName: 'Portfolio', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/kirbyware-website-icon.png',
  pathPrefix: `/gatsby-starter-aerial/`, // This path is subpath of your hosting https://domain/portfolio
  // social

  siteName: 'kirbyware LLC',
  authorName: 'Mark Kirby',
  siteHeading: 'Accelerate your implementation of today\'s leading technologies',
  siteSocialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/kirbyware',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/kirbyware/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/kirbyware_io'
    },
    // {
    //   icon: 'fa-facebook',
    //   name: 'Facebook',
    //   url: 'https://facebook.com/',
    // },
    {
      icon: 'fa-info-circle',
      name: 'About Mark',
      url: 'https://www.kirbymark.dev/about',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:mark@kirbyware.com',
    },
  ],
};
